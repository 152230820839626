import { template as template_2d0398395b3743e2b1b0b49a82dab2b4 } from "@ember/template-compiler";
const SidebarSectionMessage = template_2d0398395b3743e2b1b0b49a82dab2b4(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
